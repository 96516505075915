<template>
  <DashboardSection>
    <template #top-right-button>
      <CustomButton
        @onClick="$router.push({ name: 'EditSubcontractor', params: { id: subcontractorId } })"
        iconClass="edit"
        label="Edit"
        color="grey"
      />
    </template>
    <template #content v-if="subcontractorDetails">
      <div class="board">
        <div class="board__section">
          <span class="board__section-heading">Name</span>
          <p>{{ subcontractorDetails.name }}</p>
        </div>
        <div class="board__section">
          <span class="board__section-heading">Company number</span>
          <p>{{ subcontractorDetails.legalId }}</p>
        </div>
        <div class="board__section">
          <span class="board__section-heading">Registered address</span>
          <p>{{ subcontractorDetails.address }}</p>
        </div>
        <div class="board__section">
          <span class="board__section-heading">VAT / GST number</span>
          <p>{{ subcontractorDetails.VATnumber }}</p>
        </div>

        <div class="board__section">
          <span class="board__section-heading">Mailing address</span>
          <p>
            {{
              subcontractorDetails.mailingAdress !== null
                ? subcontractorDetails.mailingAdress
                : subcontractorDetails.address
            }}
          </p>
        </div>
        <div class="board__section">
          <span class="board__section-heading">Standard unit cost</span>
          <p>{{ subcontractorDetails.standardUnitCost }}</p>
        </div>

        <div class="board__section">
          <span class="board__section-heading">Client contact</span>
          <p>{{ subcontractorDetails.leadContactName }}</p>
          <p>{{ subcontractorDetails.leadContactEmail }}</p>
          <p>{{ subcontractorDetails.leadContactPhone }}</p>
        </div>

        <div class="board__section">
          <span class="board__section-heading">Finance contact</span>
          <p>{{ subcontractorDetails.financeContactName }}</p>
          <p>{{ subcontractorDetails.financeContactEmail }}</p>
          <p>{{ subcontractorDetails.financeContactPhone }}</p>
        </div>
        <div class="board__section">
          <span class="board__section-heading">Standard payment term</span>
          <p>{{ subcontractorDetails.standardPaymentTerm }}</p>
        </div>
        <div class="board__section">
          <span class="board__section-heading">Standard delivery unit</span>
          <p>{{ subcontractorDetails.standardDeliveryUnit }}</p>
        </div>
        <div class="board__section">
          <span class="board__section-heading">Bank account</span>
          <p>{{ subcontractorDetails.bankAccount }}</p>
        </div>
        <div class="board__section">
          <span class="board__section-heading">Bank sort code / BSB number</span>
          <p>{{ subcontractorDetails.bankSortCode }}</p>
        </div>
      </div>
    </template>
  </DashboardSection>
</template>
<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useStore } from '@/store'
import { useRoute } from 'vue-router'
import DashboardSection from '@/components/atom/DashboardSection.vue'
import CustomButton from '@/components/atom/CustomButton.vue'

export default defineComponent({
  components: {
    DashboardSection,
    CustomButton
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const subcontractorId = route.params.id
    const subcontractorDetails = computed(() => store.state.studio.SubcontractorDetails)

    return { subcontractorDetails, subcontractorId }
  }
})
</script>
<style lang="sass" scoped>
.board
  display: grid
  grid-template-columns: 18rem 18rem
  grid-gap: 16px
  @media( max-width: $ms-breakpoint)
    height: auto
  &__section
    display: inline-block
    width: 100%
    background-color: $white
    padding: 1rem
    border-radius: 4px
    @media( max-width: $ms-breakpoint)
      width: 100%
  &__section-heading
    font-weight: 700
</style>
