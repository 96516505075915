
import { defineComponent, computed } from 'vue'
import { useStore } from '@/store'
import { useRoute } from 'vue-router'
import DashboardSection from '@/components/atom/DashboardSection.vue'
import CustomButton from '@/components/atom/CustomButton.vue'

export default defineComponent({
  components: {
    DashboardSection,
    CustomButton
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const subcontractorId = route.params.id
    const subcontractorDetails = computed(() => store.state.studio.SubcontractorDetails)

    return { subcontractorDetails, subcontractorId }
  }
})
